@import '@/styles/mixins.scss';

.navbar-wrap {
    opacity: 0.01;

    &.enter {
        opacity: 1;
        transition: 150ms ease-out;
        transition-delay: 50ms;
    }

    &.landing-page {
        position: relative;
        background-color: var(--colors-mono-white-opacity-80);

        height: 60px;
        opacity: 1;

        z-index: 100000;
        backdrop-filter: blur(3px);

        .navbar {
            padding: 12px 80px;
        }

        .button {
            @include subtitle-S;
            height: 36px;
            padding: 8px 18px;

            opacity: 1;
        }

        &.scrolled {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
        }

        .menu-item > a {
            @include subtitle-S;
            color: var(--colors-gray-dark-20);
        }
    }
}

.navbar {
    @include flex-center;
    position: relative;
    justify-content: space-between;
    padding: 20px 70px;
    margin: auto;
    max-width: 1440px;
}

.logo {
    width: 84px;
    height: 28px;

    svg {
        transition: 150ms ease-out;

        &:hover {
            transform: scale(1.2);
        }
    }
}

.menu {
    @include flex-center;
    gap: 40px;
}

.icon-dark {
    stroke: var(--colors-primary-dark-70);
}

.button {
    opacity: 0.01;

    &.enter {
        opacity: 1;
        transition: 150ms ease-out;
        transition-delay: 100ms;
        animation: expandRetraction 600ms cubic-bezier(0.51, 0.92, 0.24, 1.15);
        animation-delay: 400ms;
    }
}

.mobile-options-wrap {
    @include flex-center;
    flex-direction: row;
    gap: 20px;

    .special-offer-menu-option svg {
        fill: var(--white) !important;
    }
}

.mobile-options-wrap {
    display: none;
}

.mobile-menu-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: var(--white);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: 300ms ease-out;
    z-index: 10;
}

.mobile-menu-contents {
    padding: 20px;
}

.mobile-menu-button {
    width: 100%;
    padding-left: 4px;

    cursor: pointer;

    svg {
        pointer-events: none;
    }
}

.mobile-menu-cta {
    width: 100%;
    margin-top: 12px;
}

.special-offer-menu-wrapper {
    display: block;
    height: 100%;

    position: relative;
}

.special-offer-menu-option {
    display: block;
    height: 100%;
    padding: 0 8px;

    cursor: pointer;

    svg {
        width: 18px;
        transition: 0.2s ease-out;
        fill: var(--colors-primary-light-90);

        &.dark {
            fill: var(--colors-gray);
        }
    }

    &:hover {
        svg {
            fill: var(--white);

            &.dark {
                fill: var(--colors-gray-dark-30);
            }
        }
    }
}

@keyframes expandRetraction {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.25);
    }

    100% {
        transform: scale(1);
    }
}

@media screen and (max-width: 960px) {
    .navbar {
        padding: 20px 70px;
    }

    .menu {
        display: none;
    }

    .mobile-options-wrap {
        display: flex;
    }

    .special-offer-menu-wrapper {
        position: static;
    }
}

// Static content

.menu-item {
    display: block;
    position: relative;
    height: 100%;

    > a {
        @include flex-center;
        @include caption-L;
        color: var(--colors-primary-light-90);
        padding: 8px;
    }

    a {
        text-decoration: none;
    }

    .chevron-down {
        stroke: var(--colors-primary-light-80);
        width: 20px;
        height: 20px;
        margin-left: 8px;
        transition: 150ms ease-out;
    }

    &:hover {
        .child-menu-wrap {
            pointer-events: auto;
        }

        .child-menu {
            max-height: 999px;
            opacity: 1;

            > div {
                opacity: 1;
            }
        }

        .arrow {
            opacity: 1;
        }

        .chevron-down {
            stroke: white;
            transform: rotate(-90deg);
        }

        > a {
            color: var(--white);
        }
    }

    &.white-bg {
        > a {
            color: var(--colors-gray);

            transition: 200ms ease-out;

            &:hover {
                color: var(--colors-gray-dark-30);
            }
        }
        .chevron-down {
            stroke: var(--colors-gray);
            transition: 200ms ease-out;

            &:hover {
                color: var(--colors-gray-dark-30);
            }
        }
    }
}

.child-menu-wrap {
    position: absolute;
    left: -40px;
    z-index: 1;
    pointer-events: none;
}

.arrow {
    position: absolute;
    top: calc(100% + 6px);
    left: 40%;
    transition: 150ms ease-out opacity;
    opacity: 0;

    z-index: 2;
    height: 6px;
    overflow: hidden;

    div {
        width: 14px;
        height: 20px;
        background: white;
        transform: rotate(45deg) translateY(4px);
    }
}

.child-menu {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 12px;
    column-gap: 8px;

    max-height: 0;
    margin-top: 12px;

    z-index: 1;
    border-radius: 8px;
    overflow: hidden;
    opacity: 0;
    transition: 150ms ease-out;

    background: white;

    box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.15);

    > div {
        transition: 150ms ease-out opacity;
        opacity: 0;
    }

    &.single-column {
        grid-template-columns: 1fr;
    }
}

.child-big-group {
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px;
    margin-left: 8px;
}

.child-group {
    @include flex-column;

    gap: 12px;
    padding: 16px;
    background: var(--colors-gray-light-95);

    border-radius: 8px;

    transition: 0.2 ease-out;

    &:hover {
        background: var(--colors-primary-light-95);
    }
}

.group-title {
    @include caption-M-upper;
    color: var(--colors-gray);
    margin-bottom: 4px;
    white-space: nowrap;

    user-select: none;
}

.child-link {
    @include caption-M;
    color: var(--colors-gray-dark-50);
    white-space: nowrap;

    transition: 0.2 ease-out;

    &:hover {
        color: var(--colors-primary);
    }
}

.view-all {
    @include caption-M;
    color: var(--colors-primary);
    white-space: nowrap;
}

#services-menu {
    .arrow {
        left: 35%;
    }

    .child-menu-wrap {
        left: -20px;
    }
}

@media screen and (max-width: 960px) {
    .navbar,
    .navbar-wrap.landing-page .navbar {
        padding: 20px 60px;
    }
}

@media screen and (max-width: 764px) {
    .navbar,
    .navbar-wrap.landing-page .navbar {
        padding: 20px 32px;
    }
}

@media screen and (max-width: 524px) {
    .navbar,
    .navbar-wrap.landing-page .navbar {
        padding: 20px;
    }
}
