@import '@/styles/mixins.scss';

.menu {
    @include flex-column;
    gap: 10px;
}

.title {
    @include subtitle-S;
    margin-bottom: 10px;
    color: #98a2b3;
}

.menu-item a {
    @include text-S;
    color: #667085;
    text-decoration: none;
}

@media screen and (max-width: 764px) {
    .title {
        margin-bottom: 0px;
    }

    .title,
    .menu-item {
        text-align: center;
    }
}
