@import '@/styles/mixins.scss';

.footer {
    // margin-top: 60px;
}

.footer-content-wrap {
    padding-top: 0 !important;
}

.content {
    @include flex-column;
    margin: auto;
    gap: 32px;

    hr {
        height: 1px;
        width: 100%;
        background-color: #e4e7ec;
        border: none;
        margin: 0;
    }
}

.logo {
    width: 112px;
}

.social-wrap {
    @include flex-column;
    justify-content: space-between;
    width: 221px;
}

.socials-mobile {
    display: none;
}

.logo-wrap {
    @include flex-column;
    gap: 10px;
}

.slogan {
    @include subtitle-S;
    color: var(--colors-gray-dark-0, #98a2b3);
}

.column-split {
    display: none;
}

.columns {
    @include flex-row;
    gap: 120px;
}

.menus {
    @include flex-row;
    justify-content: space-between;
    flex-grow: 1;
}

.bottom {
    @include flex-row;
    justify-content: space-between;
    align-items: center;
}

.copyright {
    @include text-S;
    max-width: 548px;
    color: #98a2b3;
}

.socials {
    @include flex-row;
    gap: 16px;
}

.logo-row {
    @include flex-row;
    gap: 32px;
}

.cyber-essentials {
    width: 100px;
    height: 120px;
    background-image: url('/images/cyber-essentials.png');
    background-repeat: no-repeat;
}

.fintech {
    width: 212px;
    height: 119px;
    background-image: url('/images/fintech.png');
    background-repeat: no-repeat;
}

.ux-award {
    width: 79px;
    height: 120px;
    background-image: url('/images/ux-award.png');
    background-repeat: no-repeat;
}

@media screen and (max-width: 1280px) {
    .bottom {
        @include flex-column;
        flex-direction: column-reverse;
        gap: 32px;
        text-align: center;
    }

    .copyright {
        text-align: center;
    }
}

@media screen and (max-width: 960px) {
    .footer {
        padding: unset;
    }

    .social-wrap {
        @include flex-row;
        width: 100%;
        align-items: flex-end;
    }

    .columns {
        @include flex-column;
        gap: unset;
    }

    .menus {
        @include flex-row;
        justify-content: space-between;
    }

    .column-split {
        display: block;
        margin: 40px 0px !important;
    }

    .cyber-essentials {
        width: 82px;
        height: 100px;
        background-image: url('/images/ce-small.png');
    }

    .fintech {
        width: 178px;
        height: 100px;
        background-image: url('/images/fintech-small.png');
    }

    .ux-award {
        width: 66px;
        height: 100px;
        background-image: url('/images/ux-award-small.png');
    }
}

@media screen and (max-width: 764px) {
    .footer-content-wrap {
        padding-top: 40px !important;
    }

    .social-wrap {
        justify-content: center;
    }

    .logo-wrap {
        align-items: center;
        text-align: center;
    }

    .menus {
        @include flex-column;
        gap: 32px;
    }

    .logo-wrap {
        gap: 24px;
    }

    .socials {
        display: none;
    }

    .socials-mobile {
        display: block;
    }
}

@media screen and (max-width: 550px) {
    .logo-row {
        @include flex-center;
        flex-direction: column;
        gap: 24px;
    }
}
